const DEFAULT_CAMPAIGN_ID = process.env.GATSBY_DEFAULT_CAMPAIGN_ID;

module.exports = {
    brandInfo: {
        phoneNumber: "18885364210",
        contactEmail: "info@carconciergecanada.com",
        address: "10 Lower Spadina Ave, Toronto ON",
        copyright: `© ${new Date().getFullYear()} Car Concierge Canada`,
        defaultCampaignId: DEFAULT_CAMPAIGN_ID || "132",
    },

    project: "ccc-loan-application",
    name: "Car Concierge Canada",
    shortName: `CCC`,
    title: `Car Concierge Canada Application`,
    description: `Get the lowest rates on the best vehicles available!`,
    reportingDomain: "carconciergecanada.com",
    siteUrl: "https://apply.carconciergecanada.com",

    homeUrl: "/",
    termsUrl: "/terms_of_use",
    privacyPolicyUrl: "/privacy_policy",
    successUrl: "/success",

    hideSuccessCtas: true,
};